<template>
    <section class="page-attention">
        <div class="row-space-tbf">
            <div class="space-left"></div>
            
            <not-paid v-if="$auth.check() && (!$auth.user().instance || ['new', 'unpaid'].includes($auth.user().instance.status))"/>
            <not-allowed v-else/>

            <div class="space-right"></div>
        </div>
    </section>
</template>

<script>
    import NotAllowed from '../General/NotAllowed'
    import NotPaid from '../General/NotPaid'

    export default {
        data(){
            return{
            }
        },
        mounted(){

        },
        components: {
            NotAllowed,
            NotPaid
        },
        methods: {
            handleAnimationLogo(anim){
                this.anim_logo = anim;
                this.anim_logo.setSpeed(1);
            }
        }
    }
</script>